// Bibliotecas
import React from 'react';
import styled, { css } from 'styled-components';

// Componentes
import Layout from 'components/layout';
import BreadCrumb from 'components/breadCrumb';
import { device } from 'components/device';
import HealthInsurance from 'components/HealthInsurance';

// Styles
const Container = styled.div`
  display: grid;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;
  padding-top: 1.875rem;

  @media ${device.laptop} {
    display: block;
    padding: 0 20px;
    overflow: hidden;
  }
`;

const Wrapper = styled.div`
  grid-column: 2 / -2;
`;

const CustosDosProcedimentos = () => {
  return (
    <Layout>
      <Container>
        <Wrapper>
          <BreadCrumb
            markdown="1. [Home](/) >
2. Coberturas e Planos de Saúde"
          />
        </Wrapper>
      </Container>

      <HealthInsurance defaultUF="SP" />
    </Layout>
  );
};

export default CustosDosProcedimentos;
